<template>
  <div class="agreement wrapper">
    <p class="title-top" />
    <!--    eslint-disable vue/no-v-html-->
    <div
      class="div-partition"
      v-html="text"
    />
  </div>
</template>

<script>
import { formatFirstUpperCase } from '@/utils/index.js'

export default {
  name: 'Userdatadeletion',
  metaInfo: {
    title: 'userdatadeletion',
    link: []
  },
  data () {
    //  <br>
    return {
      text: `
      My dear customer!Before you officially start to cancel your account, we make the following important reminders for you: Account cancellation is an unrecoverable operation.After canceling the account,You will no longer be able to log in with this account and use all services applicable to this account system (here in after collectively referred to as "netboom services"),and you will not be able to retrieve any content and information that you added or bound through the account, even If you choose the same email address to register the account again, it will not be retrieved.In order to avoid inconvenience to you, please consider carefully before canceling your account.If you decide to cancel your account after careful consideration, you must first carefully read and fully understand all the contents of the "NetBoom Account Cancellation Agreement" (here in after referred to as the "Agreement").
  You should have the appropriate behavioral ability to sign this agreement.If you do not have the behavioral capabilities that are appropriate with the behavior of signing this agreement, please read and perform corresponding operations with your guardian, and ensure that the guardian agrees to all your behaviors.In this case, you and your guardian shall bear all consequences arising therefrom in accordance with relevant regulations.
  You understand and agree that once the account is canceled, you are willing to bear all (possible) consequences, including but not limited to: <br> <br>
  1.After the account is canceled, you will no longer be able to log in/use/continue to use NetBoom related products and services with that account; and the account will not be restored.Even if you apply to Netboom again with the same email account after account cancellation, the account will be the new registered account by default. <br> <br>
  2.After the account is canceled, all contents, information, data and records under Netboom related products and services that you have logged in and used through the account will be deleted. After being deleted, you can no longer retrieve, access, transmit, obtain, continue to use and retrieve, nor have the right to ask us to find them.Contents, information, data or records include but are not limited to: <br> <br>
   (1)Your personal information under the account (including but not limited to avatar, nickname, email address, password, and other personal information); <br> <br>
   (2)All the content you have published under this account (including but not limited to pictures, videos, music columns, comments, likes, reposts, and other interactions, etc.); <br> <br>
   (3)All other content, information, data, and records under the account. <br> <br>
  3.After the account is canceled, Netboom will clear all rights and interests (if any) under the account.You understand and agree to waive the rights and interests generated but not consumed during the use of Netboom related products and services and the expected benefits that may arise in the future by canceling the account. Such rights and interests or expected benefits include but are not limited to: <br> <br>
   (1)Your gold coins, points, and commodities were purchased but not delivered by the mall under this account. <br> <br>
   (2)Your various membership subscription benefits under this account (including but not limited to NetBoom VIP, etc.); <br> <br>
   (3)Future earnings rights and interests of the content you publish under this account;Paid content purchased by you under this account (including but not limited to vip accounts, etc.); <br> <br>
   (4)Your various identity rights under the account; <br> <br>
   (5)Paid content purchased by you under this account (including but not limited to vip accounts, etc.); <br> <br>
  4.Once the NetBoom account is canceled, the relevant user agreement and other rights and obligations documents that you have signed with us through the account will be terminated accordingly, unless NetBoom and you have agreed to continue to be valid or otherwise provided by laws and regulations. <br> <br>
  5.All other (possible) consequences arising from the cancellation of Netboom account will be borne by you. <br> <br>
  If you have any questions about this agreement, please send an email to our customer service email:
  <a
          href="mailto:support-android@netboom.com"
          target="_blank"
          class="url"
          style="
    color: #990000;
    text-decoration: none;
  "
        >【support-android@netboom.com】</a>.
  
        `,
      project: ' Netboom Game +'
    }
  },
  created () {
    const flavor = this.$route.query.flavor || ' Netboom Game +'
    this.project = formatFirstUpperCase(flavor)
  }
}
</script>

<style lang="less">
@import "~@/assets/styles/agreement.less";
</style>
<style lang="less" scoped>
.wrapper {
  padding: 0 5%;
  background: #f0f0f0;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  min-height: 100%;
  box-sizing: border-box;
}
</style>
